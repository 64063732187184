// Have to use 'New' and capitalised 'For You'
// to match how it's being tracking in the app
enum EventListingsType {
  all = 'All',
  forYou = 'For You',
  justAnnounced = 'New',
  raPicks = 'RA Picks',
  savedEvents = 'Saved events',
}

const EVENT_LISTINGS_TYPE = Object.freeze(EventListingsType)

export { EventListingsType }
export default EVENT_LISTINGS_TYPE
