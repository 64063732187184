class LocationFilterValue {
  public readonly distance: string

  public readonly latitude: number

  public readonly longitude: number

  constructor(distance: string, latitude: number, longitude: number) {
    this.distance = distance
    this.latitude = latitude
    this.longitude = longitude
  }
}

export default LocationFilterValue
