import axios from 'axios'
import EventDto from 'interfaces/gql/EventDto'
import { useState } from 'react'

const useKevelTracking = (data: EventDto[]) => {
  const bumpImpressionUrls = data
    .filter((event: EventDto) => event.bump)
    .map((event: EventDto) => event.bump.impressionUrl)

  const [bumpImpressionsTracked, setBumpImpressionsTracked] = useState([])

  bumpImpressionUrls.forEach((impressionUrl: string) => {
    if (!bumpImpressionsTracked.includes(impressionUrl)) {
      axios.get(impressionUrl)
      setBumpImpressionsTracked((prev) => [...prev, impressionUrl])
    }
  })
}

export default useKevelTracking
