import { isSameDay } from 'date-fns'
import getEventListStartDate from './getEventListStartDate'
import DateFilterValue from '../date-filter-value'

class StartDateFilterValue extends DateFilterValue {
  public readonly isToday: boolean

  constructor(startDateString: string, serverTime: Date, areaTimeZone: string) {
    const startDate = getEventListStartDate({
      startDateString,
      areaTimeZone,
      serverTime,
    })
    super(startDate)
    this.isToday = isSameDay(serverTime, startDate)
  }
}

export default StartDateFilterValue
