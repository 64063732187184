import getConfig from 'next/config'
import { utcToZonedTime } from 'date-fns-tz'
import isSameDay from 'date-fns/isSameDay'
import getHours from 'date-fns/getHours'
import addDays from 'date-fns/addDays'
import { parseISO } from 'date-fns'

const {
  publicRuntimeConfig: { EVENTS_LISTING_CONFIG },
} = getConfig()

const getEventListStartDate = ({
  startDateString,
  areaTimeZone,
  serverTime,
}: {
  startDateString: string
  areaTimeZone: string
  serverTime: Date
}): Date => {
  const startDate = startDateString
    ? parseISO(startDateString)
    : new Date(serverTime)

  const areaLocalTime = utcToZonedTime(serverTime, areaTimeZone)

  if (
    isSameDay(areaLocalTime, startDate) &&
    getHours(areaLocalTime) <
      EVENTS_LISTING_CONFIG.SHOW_EVENTS_FROM_PREVIOUS_DAY_CUTOFF_HOUR
  ) {
    // If you're browsing events in an area and it's early morning in that area
    // also return events from the previous day
    return addDays(startDate, -1)
  }

  return startDate
}

export default getEventListStartDate
