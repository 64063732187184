import AreaDto from 'interfaces/gql/AreaDto'
import { parseISO } from 'date-fns'
import FilterValue from './filter-value'
import StartDateFilterValue from './start-date-filter-value'
import DateFilterValue from './date-filter-value'
import LocationFilterValue from './location-filter-value'

class AreasFilterValue extends FilterValue<number> {
  public readonly areaName: string

  public readonly area: AreaDto

  constructor(area: AreaDto, areaName: string) {
    super(parseInt(area.id, 10))
    this.areaName = areaName
    this.area = area
  }
}

class EndDateFilterValue extends DateFilterValue {
  constructor(endDateString: string) {
    const endDate = parseISO(endDateString)
    super(endDate)
  }
}

export {
  FilterValue,
  AreasFilterValue,
  StartDateFilterValue,
  EndDateFilterValue,
  LocationFilterValue,
}
