import { useEventListingsFiltersContext } from 'components/events/pages/events-home/event-listings/event-listings-filter-context'
import EventListingsLocationType from 'enums/event-listings-location-type'
import { Fields } from 'enums/listing'
import FilterInputDto from 'interfaces/gql/FilterInputDto'
import { useRouter } from 'next/router'

const useAddLocationFilter = (filters: FilterInputDto): FilterInputDto => {
  const {
    query: { latitude, longitude, distance },
  } = useRouter()
  const { locationType } = useEventListingsFiltersContext()

  if (
    locationType === EventListingsLocationType.geoLocation &&
    latitude &&
    longitude &&
    distance
  ) {
    return {
      ...filters,
      [Fields.Location]: {
        latitude: Number(latitude),
        longitude: Number(longitude),
        distance: distance as string,
      },
      [Fields.Areas]: undefined,
    }
  }

  return filters
}

export default useAddLocationFilter
