import { formatDateForUrl } from 'lib/eventUrlFormatters'

import FilterValue from '../filter-value'

class DateFilterValue extends FilterValue<string> {
  constructor(value: Date) {
    super(formatDateForUrl(value))
  }
}

export { formatDateForUrl }
export default DateFilterValue
